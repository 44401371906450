<template>
  <app-card-link-background
    class="visibility-auto"
    :style="{
      width: width ? `${width}px` : undefined,
    }"
    :to="{
      name: 'trainers-slug',
      params: {
        slug: teacher.slug!,
      },
    }"
  >
    <figure class="group">
      <div class="relative aspect-square w-full overflow-hidden rounded">
        <app-user-avatar
          :avatar-url="teacher.avatar_url"
          class="absolute inset-0 object-cover transition duration-500 group-hover:scale-105"
          :name="teacher.full_name"
          :size="400"
          tile
        />
        <teacher-pinned-icon
          v-if="teacher.pinned"
          class="absolute bottom-2 left-2 rounded-sm bg-white p-1 text-black"
        />
      </div>

      <figcaption class="px-2 pt-2">
        <p class="font-semibold">{{ teacher.full_name }}</p>
        <teacher-companie-logos
          v-if="teacher.companies.length > 0"
          class="mt-1"
          :companies="teacher.companies"
          :height="16"
          variant="flat"
        />
        <p
          v-if="showDescriptionShort && teacher.description_short"
          class="mt-3 text-sm text-subtle"
        >
          {{ teacher.description_short }}
        </p>
      </figcaption>
    </figure>
  </app-card-link-background>
</template>

<script lang="ts" setup>
import {
  type CarouselProvidedState,
  CarouselProvideKey,
} from "~/shared/carousel/carousel.model";

defineProps<{
  teacher: Pick<
    DatabaseTable<"teachers">,
    | "id"
    | "full_name"
    | "companies"
    | "avatar_url"
    | "slug"
    | "pinned"
    | "description_short"
  >;
  showDescriptionShort?: boolean;
}>();

const width = computed(
  () =>
    inject<CarouselProvidedState | null>(CarouselProvideKey, null)?.itemWidth,
);
</script>
